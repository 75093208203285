import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllPages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('clinic/admin/appointments',{ params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchPost(ctx, queryParams) {
      var id=queryParams.id;

      return new Promise((resolve, reject) => {
        axios
          .get(`cms/admin/blogpost/${id}`,{ params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
